import type { Query as Query_rhrwr9 } from '../pages/seat-management/seats/index.page';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "active_visits": {
    _visitId: (visitId: string | number) => ({
      "order": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/active-visits/[visitId]/order' as const, query: { visitId }, hash: url?.hash })
      },
      "settlement": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/active-visits/[visitId]/settlement' as const, query: { visitId }, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/active-visits/[visitId]' as const, query: { visitId }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/active-visits' as const, hash: url?.hash })
  },
  "auth": {
    "sign_in": {
      "callback": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth/sign-in/callback' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth/sign-in' as const, hash: url?.hash })
    }
  },
  "histories": {
    _visitId: (visitId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/histories/[visitId]' as const, query: { visitId }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/histories' as const, hash: url?.hash })
  },
  "seat_management": {
    "seats": {
      _seatId: (seatId: string | number) => ({
        "registerCard": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/seat-management/seats/[seatId]/registerCard' as const, query: { seatId }, hash: url?.hash })
        }
      }),
      $url: (url: { query: Query_rhrwr9, hash?: string | undefined }) => ({ pathname: '/seat-management/seats' as const, query: url.query, hash: url.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/seat-management' as const, hash: url?.hash })
  },
  "visits": {
    _visitId: (visitId: string | number) => ({
      "orders": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/visits/[visitId]/orders' as const, query: { visitId }, hash: url?.hash })
      },
      "settlement": {
        "detail": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/visits/[visitId]/settlement/detail' as const, query: { visitId }, hash: url?.hash })
        }
      }
    })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  circles_svg: '/circles.svg',
  next_svg: '/next.svg',
  speria_logo_png: '/speria_logo.png',
  speria_logo_2x_png: '/speria_logo_2x.png',
  speria_logo_3x_png: '/speria_logo_3x.png',
  turborepo_svg: '/turborepo.svg',
  vercel_svg: '/vercel.svg'
} as const;

export type StaticPath = typeof staticPath;
