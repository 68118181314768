import * as Sentry from "@sentry/nextjs";
import { initializeApp, getApps, FirebaseError } from "firebase/app";
import {
  getAuth,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  signOut,
} from "firebase/auth";

import { pagesPath } from "./$path";

if (!process.env.NEXT_PUBLIC_FIREBASE_API_KEY) {
  throw new Error("Firebase API Key is missing.");
}

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const apps = getApps();
const app = apps.length === 0 ? initializeApp(firebaseConfig) : apps[0];
const auth = getAuth(app);

/**
 * NOTE: Firebase のメールリンクログインは、メールリンク内の URL とメールアドレスの 2 要素によって認証が完了する。
 * そのため、リンク押下によってログイン完了とするためには、入力されたメールアドレスをブラウザに保持しておく必要がある & ログインを試みたブラウザと同じブラウザで URL を開く必要がある。
 */
const SIGN_IN_EMAIL_STORAGE_KEY = "sign_in_email";

export const sendSignInLinkToEmail = async (email: string) => {
  await firebaseSendSignInLinkToEmail(auth, email, {
    url: `${window.location.origin}${
      pagesPath.auth.sign_in.callback.$url().pathname
    }`,
    handleCodeInApp: true,
  });

  window.localStorage.setItem(SIGN_IN_EMAIL_STORAGE_KEY, email);
};

export const getStoredSignInEmail = () => {
  return window.localStorage.getItem(SIGN_IN_EMAIL_STORAGE_KEY);
};

export const signInWithEmailLink = async ({
  email,
  url,
}: {
  email: string;
  url: string;
}) => {
  await firebaseSignInWithEmailLink(auth, email, url);
};

export const isSignInWithEmailLink = (url: string) => {
  return firebaseIsSignInWithEmailLink(auth, url);
};

// ref: https://firebase.google.com/docs/auth/admin/errors?hl=ja
export const firebaseAuthErrorToMessage = (error: FirebaseError) => {
  switch (error.code) {
    // NOTE: メールリンクを複数送信した場合、最新のメール以外の URL を開くとこのエラーとなる
    case "auth/invalid-action-code": {
      return "このURLは使用することができません。再度ログインを行ってください。";
    }
    // TODO: 必要に応じて起こり得るエラーを追加する
    default: {
      Sentry.captureMessage(`unhandled firebase error code: ${error.code}`);

      return "ログインに失敗しました。再度ログインを行ってください。";
    }
  }
};

export const getFirebaseIdToken = async () => {
  if (!auth.currentUser) {
    return null;
  }

  return await auth.currentUser.getIdToken();
};

/**
 * NOTE: セッションはサーバー側で管理しており、Firebase のログイン状態はどこにおいても参照されない。
 * そのため、サーバー側でセッションを作成したタイミング含め、いつログアウトしても良い。
 * また、Firebase でログイン状態でも、別のメールアドレスによる Firebase のログイン操作を行うことも可能であるため、ずっとログアウトしなくても問題無い。
 */
export const signOutFromFirebase = async () => {
  await signOut(auth);
};
