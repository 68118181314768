import { useQuery } from "@apollo/client";

import { ShopUserDocument } from "src/gql/__generated__/graphql";

// UseShopUser_ShopUserFragment を利用できるはずだが、型が正しくならず、利用箇所に右記のようなエラーが起こるため、自前で定義している	"Type '{ ' $fragmentRefs'?: { UseShopUser_ShopUserFragment: UseShopUser_ShopUserFragment; } | undefined; }' is not assignable to type 'UseShopUser_ShopUserFragment'.\n  Type '{ ' $fragmentRefs'?: { UseShopUser_ShopUserFragment: UseShopUser_ShopUserFragment; } | undefined; }' is missing the following properties from type '{ id: string; email: string; shop: { id: string; name: string; }; }': id, email, shop",
// TODO: 修正する
export type ShopUser = {
  id: string;
  email: string;
  shop: {
    id: string;
    name: string;
  };
};

export const useShopUser = () => {
  const { data, loading } = useQuery(ShopUserDocument);

  return {
    loading,
    shopUser: (data?.shopUser ?? null) as ShopUser | null,
  };
};
