import { HStack } from "@chakra-ui/react";
import { Text } from "@repo/ui/text";
import { memo, ReactNode } from "react";

import { Logo } from "./Logo";

type Props = {
  rightContent?: ReactNode;
};

export const Header = memo<Props>(({ rightContent }) => {
  return (
    <HStack justifyContent="space-between" px={4} h="72px">
      <HStack spacing={2}>
        <Logo />
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="medium"
          color="semantic.contents.primary"
        >
          Speria Staff
        </Text>
      </HStack>

      {rightContent}
    </HStack>
  );
});
