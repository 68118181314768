import { ApolloError } from "@apollo/client";
import { useToast } from "@chakra-ui/react";

export const useApolloErrorToast = () => {
  const toast = useToast();

  const onError = (error: ApolloError) => {
    const message = error.message;

    toast({
      title: message,
      status: "error",
    });
  };

  return onError;
};
