import { useApolloClient, useMutation } from "@apollo/client";
import { Stack, useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FC, ReactNode } from "react";

import { Header } from "../Header";

import { AuthenticatedMenu } from "./AuthenticatedMenu";

import { DestroyAuthSessionDocument } from "src/gql/__generated__/graphql";
import { useApolloErrorToast } from "src/hooks/useApolloErrorToast";
import { useShopUser } from "src/hooks/useShopUser";
import { pagesPath } from "src/utils/$path";
import { signOutFromFirebase } from "src/utils/firebaseAuth";

type Props = {
  children: ReactNode;
};

export const AuthenticatedMainLayout: FC<Props> = ({ children }) => {
  const toast = useToast();
  const router = useRouter();
  const errorToast = useApolloErrorToast();
  const apolloClient = useApolloClient();

  const { shopUser } = useShopUser();

  const [destroyAuthSession] = useMutation(DestroyAuthSessionDocument, {
    onCompleted: () => {
      toast({
        title: "ログアウトしました",
        status: "success",
      });

      router.push(pagesPath.auth.sign_in.$url());
    },
    onError: errorToast,
  });

  const handleLogout = async () => {
    await destroyAuthSession();
    await signOutFromFirebase();
    await apolloClient.cache.reset();
  };

  return (
    <Stack minH="$100vh" spacing={4}>
      <Header
        rightContent={
          <AuthenticatedMenu shopUser={shopUser} onLogout={handleLogout} />
        }
      />
      <Stack spacing={8}>{children}</Stack>
    </Stack>
  );
};
